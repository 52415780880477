export const isBrowser = typeof global.localStorage !== 'undefined'

export const getStateFromStorage = (state) =>
  Object.assign({user: (isBrowser ? JSON.parse(window.localStorage.getItem('hrefcool')) : null) || {}}, state)

export const storeStateInStorage = (state, user) => {
  Object.assign(state.user, user)
  if (isBrowser)
    localStorage.setItem('hrefcool', JSON.stringify(state.user))
  return state
}
