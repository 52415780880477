import { renderToString } from 'hyperapp-render'

export default (state, actions, view, output) => {
  const tag = '</html>'
  const fs = nodeRequire('fs')
  const html = fs.readFileSync(output + '/index.html', 'utf8')
  const pathlist = ['/', '/about']
  // Pre-render the category pages.
  for (let top in state.cat) {
    pathlist.push(`/${top}`)
    for (let sub in state.cat[top]) {
      pathlist.push(`/${top}/${sub}`)
    }
  }
  // console.log(pathlist)

  // Okay, generate the files.
  for (let i in pathlist) {
    let path = pathlist[i], dir = output + path
    if (!dir.endsWith('/')) { dir += '/' }
    state.location.pathname = path
    let main = renderToString(view, state, actions)
    console.log(dir + 'index.html')
    try { fs.mkdirSync(dir, {recursive: true}) } catch (err) { }
    fs.writeFileSync(dir + 'index.html', '<!DOCTYPE html>' +
      main.replace(/\/>/g, '>'))
  }
}
