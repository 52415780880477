import { h, app } from 'hyperapp'
import { getStateFromStorage, isBrowser } from './js/utils'
import { location } from '@kickscondor/router'

import state from './js/state'
import actions from './js/actions'
import view from './js/view'
import prerender from './js/prerender'

var loc = location()
state.location = loc.state
actions.location = loc.actions

const fullState = getStateFromStorage(state)

if (!isBrowser) {
  prerender(fullState, actions, view, './dist')
} else {
  const main = app(fullState, actions, view, document)
  const unsubscribe = loc.subscribe(main.location)
}
