import { h } from 'hyperapp'
import { Link, Route, Switch } from '@kickscondor/router'
import images from '../images/*.png'
import screens from '../screens/*.png'

import 'normalize.css'
import '../css/hrefcool'
import '../millennium/stylesheet'

const HToText = function (ary) {
  var text = ''
  if (ary) {
    for (var i = 0, len = ary.length; i < len; i++) {
      var vnode = ary[i]
      if (typeof vnode.children !== 'undefined') {
        text += HToText (vnode.children)
      } else {
        text += vnode
      }
    }
  }
  return text
}

const HReplace = function (state, ary) {
  return ary.map(vnode => {
    return (typeof vnode.children !== 'undefined' ?
      Object.assign({}, vnode, {children: HReplace(state, vnode.children)}) :
      vnode.replace(/{{([^}]+)}}/g, x => state[x.slice(2, -2).trim()]))
  })
}

const Welcome = ({ match }) => (state, actions) =>
  <div id="about">
    <h3>New: <a href="/2010s">"Links of the Decade"</a></h3>
  </div>

const AboutCool = ({ match }) => (state, actions) =>
  <div id="about">
    <h3>Href dot... coool??</h3>
    {HReplace(state, state.notes['About'])}
  </div>

const Subcategories = ({ match }) => (state, actions) => {
  let keys = Object.keys(state.cat[match.params.top]).sort()
  if (match.params.top === 'Time-Depth')
    keys = [ "1m", "5m", "10m", "1h", "1w", "∞" ]
  return <div id="sub">
    {match.params.top !== 'Secret' &&
      keys.map(k => <span>/<Link to={`/${match.params.top}/${k}`}
        class={state.location.pathname.startsWith(`/${match.params.top}/${k}`) ? 'active' : ''}>{k.replace('+', ' ')}</Link> </span>)}
  </div>
}

const LinkListings = ({ match }) => (state, actions) => {
  let cat = `${match.params.top}/${match.params.sub}`
  return <div id="focus">
    <div class="listings h-entry">
      <h3 class="p-name">{cat.replace('+', ' ')}</h3>
      <a class="u-author" href="/">href.cool</a>
      <div class="note p-summary">{state.notes[cat]}</div>
      <div class="entries">
        {state.cat[match.params.top][match.params.sub].map(link => {
          let hdr = link.title, scr = "", typ = "", hdrc = null
          let realurl = link.url, domain = null
          if (!hdr.includes(' ')) {
            hdrc = 'fbr'
          }
          if (realurl != null) {
            if (realurl.startsWith('/images/')) {
              // console.log([realurl, images[realurl.slice(8)]])
              realurl = images[realurl.slice(8)]
            } else if (realurl.startsWith('http')) {
              domain = realurl.match(/^https?:\/\/([^/]+)/)[1]
            }
            hdr = <a class="h-cite u-bookmark-of" href={realurl}>{hdr}</a>
          }
          if (typeof(link.screen) !== 'undefined')
            scr = <div class="thumb"><a href={realurl}><img src={screens[link.screen]} /></a></div>
          if (typeof(link.format) !== 'undefined')
            typ = <div class="meta">
              <Link to={`/Web/${link.format}`}>{link.format}</Link>, <Link to={`/Time-Depth/${link.depth}`}>{link.depth}</Link></div>
          return <article class="entry">
            <header>
              <h4 class={hdrc}>{hdr}</h4>{typ}
            </header>
            <section><div class="descr">{link.html}</div>{scr}
              {domain && <div class="domain">{domain}</div>}
            </section>
          </article>
        })}
      </div>
    </div>
  </div>
}

const Head = ({ location }) => (state, actions) => {
  let title = location.pathname.slice(1), desc = ''
  if (title) {
    desc = state.notes[title]
    title = `href.cool: ${title}`
  } else {
    desc = 'The final heartbeats of our beloved www.'
    title = 'href.cool'
  }

  return <head>
    <meta charset="utf-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <title>{title}</title>
    <meta name="description" content="The final heartbeats of our beloved www." />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <meta name="mobile-web-app-capable" content="yes" />
    <meta name="apple-mobile-web-app-capable" content="yes" />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:creator" content="@kickscondor" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={HToText(desc)} />
    <meta name="twitter:image:src" content={`https://href.cool${images['avatar']}`} />
    <meta content="black-translucent" name="apple-mobile-web-app-status-bar-style" />
    <link rel="me" href="https://href.cool/" />
    <link rel="me" href="https://www.kickscondor.com" />
    <link rel="me" href="mailto:kicks@kickscondor.com" />
    <link rel="webmention" href="https://webmention.io/href.cool/webmention" />
    <link rel="pingback" href="https://webmention.io/href.cool/xmlrpc" />
    {parcelCss && <link rel="stylesheet" href={parcelCss} />}
    {parcelJs && <script src={parcelJs} defer />}
  </head>
}

export default (state, {retheme}) => {
  let theme = state.user.theme || 'default'
  return <html lang="en">
    <Route render={Head} />
    <body class={theme}>
      <div id="main">
        <div id="menu" class="h-card">
          <div class="invis">
            <img class="u-photo" alt="" src={images['avatar']} />
            <a class="p-name u-url" href="/">href.cool</a>
          </div>
          <h1><a href="/"><img src={images[`${theme}-logo`]} /></a></h1>
          <p class="intro"><span class="p-note">The final heartbeats of our beloved www.</span>
          &nbsp;<Link to="/about">&#x1f4ba;</Link>
          &nbsp;(<a href="#" onclick={_ => retheme('yellow')}>&#x1f4f0;</a>
           &nbsp;<a href="#" onclick={_ => retheme('olden')}>&#x1f4bb;</a>
           &nbsp;<a href="#" onclick={_ => retheme('default')}>&#x1f390;</a>)</p>
          <div id="top">
            { Object.keys(state.cat).sort()
              .filter(k => k !== 'Web' && k !== 'Time-Depth' && k !== 'Secret')
              .concat(['Web', 'Time-Depth'])
              .map(k => <span id={k}><Link to={`/${k}`} class={state.location.pathname.startsWith(`/${k}`) ? 'active' : ''}>{k}</Link> </span>) }
          </div>
        </div>
        <div id="dir">
          <Switch>
            <Route path="/" render={Welcome} />
            <Route path="/about" render={AboutCool} />
            <Route parent path="/:top" render={Subcategories} />
          </Switch>
          <hr />
          <Route parent path="/:top/:sub" render={LinkListings} />
        </div>
      </div>
    </body>
  </html>
}
